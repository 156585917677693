.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize !important;
}
