@import 'utility/variables';

.sticky-header {
  position: sticky;

  &::after {
    content: '';
    clear: both;
    display: table;
  }
}

.icon-header {
  .icon-container {
    background-color: $primary-color;
    height: 40px;
    width: 40px;
    margin: 0 16px 0 0;
    border-radius: 5px;

    // float: left;
    // position: absolute;
    .mat-icon {
      color: white;
      font-size: 30px;
      text-align: center;
      line-height: 35px;
      height: 27px;
      width: 27px;
    }

    .mat-spinner {
      margin: 12.5px;

      circle {
        stroke: white;
        -webkit-font-smoothing: antialiased;
      }
    }
  }

  h1 {
    margin-bottom: 3px;
    padding-top: 7px;
  }

  p.subtitle {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .sticky-header {
    padding: 10px 15px 10px 50px;

    .left-column {
      width: calc(100% - 130px);

      .icon-header {
        h1 {
          margin: 0;
          padding: 5px 0 0;
          font-size: 18px;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .subtitle {
          display: none;
        }

        .icon-container {
          display: none;
        }
      }
    }

    .right-column {
      padding: 0;

      button {
        line-height: 21px;
        height: 30px;
        width: 30px;
        min-width: 0;
        padding: 0 10px;
      }

      :last-child {
        margin-right: 0 !important;
      }

      .secondary-btn,
      .primary-btn {
        margin-right: 12px !important;
        padding: 0 3px;

        .mat-button-wrapper {
          .mat-icon {
            font-size: 17px;
            height: 17px;
            width: 17px;
            margin: 0;
          }

          span {
            display: none;
          }
        }
      }
    }
  }
}
